import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../AuthContext'; // Пример подключения контекста авторизации
import s from '../styles/Menu.module.css';
import { useNavigate } from 'react-router-dom';
import LanguageSelectCard from './LanguageSelectCard';
import { useTranslation } from 'react-i18next';


function SettingsPage() {
  const { t } = useTranslation(); // t используется для получения перевода

  const { token, currentUser } = useContext(AuthContext); // Получаем текущее состояние пользователя
  const [darkMode, setDarkMode] = useState(false); // Управление темой
  const [notificationsEnabled, setNotificationsEnabled] = useState(true); // Пример настройки уведомлений
  const [privacyMode, setPrivacyMode] = useState(false); // Пример настройки приватности
  const [profile, setProfile] = useState(null);  // Состояние для хранения данных профиля
  const [error, setError] = useState(null);  // Состояние ошибки
  const navigate = useNavigate();

 


  // Функция получения профиля текущего пользователя
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        // Проверяем, есть ли данные initData в localStorage (для пользователей из Telegram)
        const token = localStorage.getItem("jwtToken"); 
        console.log('токен:',token)
        let options = {
          method: 'GET',  // Метод указывается всегда
        };

        // Если есть initData (пользователь из Telegram), добавляем его в заголовки
        if (token) {
          options.headers = {
            "Content-Type": "application/json",
            "Authorization": token,  // Передаем JWT в заголовке
          };
        } else {
          // Если пользователь не из Telegram, включаем поддержку сессий через куки
          options.credentials = 'include';
        }

        const response = await fetch('/api/profile', options);



        if (response.ok) {
          const data = await response.json();
          setProfile(data);
        } else {
          if (response.status === 404) {
            navigate('/register');  // Перенаправляем, если профиль не найден
          } else {
            console.error('Error fetching profile');
            setError('Error fetching profile');
            
          }
        }
      } catch (error) {
        console.error('Error:', error);
      
      } 
      // finally {
      //   setLoading(false);  // Завершаем загрузку
      // }
    };

    fetchProfile();
  }, [navigate]);


 // Прочитать тему из localStorage при первой загрузке приложения
 useEffect(() => {
  const savedTheme = localStorage.getItem('darkMode');
  if (savedTheme === 'true') {
    setDarkMode(true);
    document.documentElement.classList.add('dark_mode');
  }
}, []);

  // Функция для переключения темы
  const toggleTheme = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('darkMode', newMode);
      if (newMode) {
        document.documentElement.classList.add('dark_mode');
      } else {
        document.documentElement.classList.remove('dark_mode');
      }
      return newMode;
    });
  };


  const toggleNotifications = () => {
    setNotificationsEnabled(!notificationsEnabled); // Включение/отключение уведомлений
  };

  const togglePrivacyMode = () => {
    setPrivacyMode(!privacyMode); // Включение/отключение приватного режима
  };






  return (
    <div className={s.prof_div}>
    <h1 className='all_str_h1'>{t('My Profile')}</h1>
    <div className={s.back2}>                
      </div>
    
    <div className={s.profile_container}>
      <div className={s.profile}>
      </div>
      <div className={s.back}>
          <Link to="/partner" className={s.btn_prev_2}>
            X
          </Link>                 
      </div>
      <div className={s.profile_div}>
            {(currentUser || token) && profile ? (
              <>
                <div className={s.name_age_2}>
                  <p>Имя: {profile.username}</p>
                  <p>Почта: {profile.email}</p>
                </div>
              </>
            ) : (
              <p>{t("Loading profile...")}</p> // Или другое сообщение, пока профиль загружается
            )}
          </div>                         
          
        <div className={s.settings_options_div}>
      <section className={s.settings_options}>
        <h2>{t("User Settings")}</h2>
        {/* Управление темой */}
        {/* <div className={s.setting_item}>
          <label htmlFor="theme-toggle">{t("Dark Mode")}</label>
          <input
            type="checkbox"
            id="theme_toggle"
            checked={darkMode}
            onChange={toggleTheme}
          />
        </div> */}
        <div className={s.setting_item}>
          <label htmlFor="theme-toggle">{t("Dark Mode")}</label>
          <label className={s.switch}>
            <input
              type="checkbox"
              id="theme-toggle"
              checked={darkMode}
              onChange={toggleTheme}
            />
            <span className={s.slider}></span>
          </label>
        </div>


        {/* Управление уведомлениями */}
        <div className={s.setting_item}>
          <label htmlFor="notifications-toggle">{t("Enable Notifications")}</label>
          <label className={s.switch}>
          <input
            type="checkbox"
            id="notifications-toggle"
            checked={notificationsEnabled}
            onChange={toggleNotifications}
          />
            <span className={s.slider}></span>
            </label>
        </div>

        {/* Управление приватным режимом */}
        <div className={s.setting_item}>
          <label htmlFor="privacy-toggle">{t("Private Mode")}</label>
          <label className={s.switch}>
          <input
            type="checkbox"
            id="privacy-toggle"
            checked={privacyMode}
            onChange={togglePrivacyMode}
          />
          <span className={s.slider}></span>
          </label>
        </div>
        <div><LanguageSelectCard/>
        </div>
        
      </section>
      
      </div>
      
      <div className={s.settings_options_div}>
      <section className={s.settings_menu}>
        <ul>
          {currentUser ? (
            <>
              <li>
              <Link to="/logout">{t("Logout")}</Link>
              </li>
            </>
            ) : token ?(
              <>
              <li>
                <Link to="/training">{t("Training")}</Link>
              </li>
            </>
          ) : (
            <>
              {/* Отображаем меню для неавторизованного пользователя */}
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/login">Login</Link>
              </li>
              <li>
                <Link to="/register">Register</Link>
              </li>
              
            </>
          )}
          
        </ul>
        
      </section>
      </div>
    </div>
    
    
      
  </div>
  );
}

export default SettingsPage;





