import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { DateContext } from "../DateContext";  // Импортируем контекст
import s from "../styles/PartnerStatisticsTable.module.css";
import dayjs from "dayjs";

const PartnerStatisticsTable = ({ partnerId }) => {
  const { startDate, endDate } = useContext(DateContext); // Получаем даты из контекста
  const [data, setData] = useState([]);
  const [expandedDate, setExpandedDate] = useState(null);
  const [dailyDetails, setDailyDetails] = useState({});

  

  useEffect(() => {
    const generateDateRangeWithZeros = () => {
      const dates = [];
      let currentDate = dayjs(endDate);
      const start = dayjs(startDate);
  
      while (currentDate.isAfter(start) || currentDate.isSame(start)) {
        dates.push({ 
          date: currentDate.format("YYYY-MM-DD"), 
          value: 0, 
          value2: 0, 
          value3: 0, 
          value4: 0, 
          value5: 0 
        });
        currentDate = currentDate.subtract(1, 'day');
      }
      return dates;
    };
  
    if (!partnerId) {
      console.warn("Partner ID is missing.");
      setData(generateDateRangeWithZeros());
      return;
    }
  
    const fetchData = async () => {
      try {
        const params = { start_date: startDate, end_date: endDate };
        const response = await axios.get(`/api/partner_stats/${partnerId}`, { params });
        const serverData = response.data;
  
        const allDates = generateDateRangeWithZeros();
        const dataWithAllDates = allDates.map(dateObj => {
          const found = serverData.find(item => item.date === dateObj.date);
          return found || dateObj;
        });
  
        setData(dataWithAllDates);
      } catch (error) {
        console.error("Error fetching data", error);
        setData(generateDateRangeWithZeros());
      }
    };
  
    fetchData();
  }, [partnerId, startDate, endDate]);
  

    const fetchDailyDetails = async (date) => {
    if (dailyDetails[date]) {
      setExpandedDate(date === expandedDate ? null : date);
      return;
    }
    try {
      const response = await axios.get(`/api/partner_stats/daily/${partnerId}/${date}`);
      setDailyDetails(prevDetails => ({ ...prevDetails, [date]: response.data }));
      setExpandedDate(date);
    } catch (error) {
      console.error("Error fetching daily details", error);
    }
  };


  return (
    <div className={s.scrollContainer}>
      <table className={s.statistics_table}>
        <thead>
          <tr>
            <th>Дата</th>
            <th>Значение</th>
            <th>Рег.</th>
            <th>Оплат</th>
            <th>Сумма</th>
            <th>Доход</th>
            <th>Подробности</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <React.Fragment key={item.date}>
              <tr>
                <td>{item.date}</td>
                <td>{item.value !== null ? item.value : "Нет данных"}</td>
                <td>{item.value2 !== null ? item.value2 : "Нет данных"}</td>
                <td>{item.value3 !== null ? item.value3 : "Нет данных"}</td>
                <td>{item.value4 !== null ? item.value4 : "Нет данных"}</td>
                <td>{item.value5 !== null ? item.value5 : "Нет данных"}</td>
                <td>
                  <button
                    className={s.details_button}
                    onClick={() => fetchDailyDetails(item.date)}
                  >
                    {expandedDate === item.date ? "Скрыть" : "Показать"}
                  </button>
                </td>
              </tr>
              {expandedDate === item.date && (
                <tr className={s.details_row}>
                  <td colSpan="7">
                    <div className="details-content">
                      <ul>
                        {dailyDetails[item.date]?.map(detail => (
                          <li key={detail.metric}>
                            {detail.metric}: {detail.value}
                          </li>
                        )) || "Загрузка..."}
                      </ul>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PartnerStatisticsTable;
