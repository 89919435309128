
// PartnerStatistics.js
import React, { useContext, useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { DateContext } from '../DateContext';  // Импортируем контекст
import OfferStatistics from './OfferStatistics';
import PartnerStatisticsTable from './PartnerStatisticsTable'; // Подключите таблицу
import s from '../styles/PartnerStatistics.module.css';

const PartnerStatistics = () => {
  const { startDate, endDate, setStartDate, setEndDate } = useContext(DateContext); // Получаем доступ к датам из контекста
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchStatistics = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const params = { start_date: startDate, end_date: endDate };
      const response = await axios.get('/api/partner/statistics', { params });
      setStatistics(response.data.offers);
    } catch (err) {
      setError('Не удалось загрузить статистику. Пожалуйста, попробуйте снова.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchStatistics();
  };

  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  return (
    <div className={s.partner_statistics}>
      <form onSubmit={handleSubmit}>
        <div>
          <label>От: </label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div>
          <label>До: </label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Загрузка...' : 'Обновить'}
        </button>
      </form>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      <div className={s.statistics_container}>
        {loading ? (
          <p>Загрузка данных...</p>
        ) : (
          statistics.length > 0 ? (
            statistics.map((stat) => <OfferStatistics key={stat.offer_id} offerData={stat} />)
          ) : (
            <p>Нет данных для отображения.</p>
          )
        )}
      </div>

     
    </div>
  );
};

export default PartnerStatistics;
