import React, { useState, useEffect } from "react";
import s from '../styles/Offers.module.css';

const OffersList = () => {
  const [offers, setOffers] = useState([]);
  const [referallinks, setReferallinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    // Запрос офферов с бэкенда
    fetch("/api/offers")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch offers");
        }
        return response.json();
      })
      .then((data) => {
        setOffers(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // Запрос реферальных ссылок с бэкенда
    fetch("/api/referallinks")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch referral links");
        }
        return response.json();
      })
      .then((referallinksData) => {
        setReferallinks(referallinksData);
        setLoading(false);
        
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [refresh]);

  const createReferralLink = async (offerId) => {
    try {
      const response = await fetch(`/api/create_referral_link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ offer_id: offerId }),
      });
  
      if (!response.ok) {
        throw new Error("Не удалось создать реферальную ссылку.");
      }
  
      const data = await response.json();
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
      alert("Ошибка при создании реферальной ссылки.");
    }
  };

  const deleteReferralLink = async (linkId) => {
    const confirmation = window.confirm("Вы уверены, что хотите удалить эту реферальную ссылку?");
  if (!confirmation) {
    return; // Если пользователь отменил, прерываем выполнение функции
  }
    try {
      const response = await fetch(`/api/delete_referral_link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ link_id: linkId }),
      });
  
      if (!response.ok) {
        throw new Error("Не удалось удалить реферальную ссылку.");
      }
  
      const data = await response.json();
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
      alert("Ошибка при удалении реферальной ссылки.");
    }
  };
  

  if (loading) return <p>Загрузка офферов...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
    <div class={s.offers}>
      <h2>Доступные офферы:</h2>
      {offers.length > 0 ? (
        <ul class={s.offers_ul}>
          {offers.map((offer) => (
            <li key={offer.id} class={s.offers_li}>
              <h3>{offer.name}</h3>
              <p>{offer.description}</p>
              <p>Тип оффера: {offer.typecpa}</p>
              <p>Сайт: <a  href={offer.url} target="_blank">{offer.url}</a></p>
              <button
                    onClick={() => createReferralLink(offer.id)}
                    className={s.create_referral_button}
                    >
                    Создать реферальную ссылку
              </button>
            </li>
            
          ))}
        </ul>
      ) : (
        <p>Нет доступных офферов.</p>
      )}
      </div>
      <div>
        <h2>Твои реферальные ссылки:</h2>
        {referallinks.length > 0 ? (
            <ul>
            {referallinks.map((referallink) => (
                <li key={referallink.id}>
                <h3>{referallink.name}</h3>
                <p>
                    Сайт: 
                    <a href={referallink.url} target="_blank" rel="noopener noreferrer">
                    {referallink.url}
                    </a>
                </p>
                <button
                    onClick={() => {
                    navigator.clipboard.writeText(referallink.url);
                    }}
                >
                    Копировать ссылку
                </button>
                <button
                    onClick={() => deleteReferralLink(referallink.id)}
                    className={s.create_referral_button}
                    >
                    Удалить ссылку
              </button>
                </li>
            ))}
            </ul>
        ) : (
            <p>Нет активных реферальных ссылок.</p>
        )}
        </div>

    </div>
    
  );
};

export default OffersList;
